import * as React from 'react';
import { withRouter } from 'react-router-dom';

interface IProps{
    location: any;
}

function ScrollToTop({ history }:{history:any}){
    React.useEffect(()=>{
        const unlisten = history.listen(()=>{
            window.scrollTo( 0, 0 );
        });
        return () => {
            unlisten();
        }
    },[history]);
    return (null);
};

export default withRouter(ScrollToTop);