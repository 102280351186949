import * as React from 'react';
import './style.scss'
import { useSelector } from 'react-redux';
import { IAppState } from '../../../state';
import { IIMages } from '../../../interfaces';

export default function FullWidth() {
    //const logo = require('../../../images/logo-white.png');
    const
        imageSelector: { images?: IIMages, error?: string } = useSelector(({ imageReducer }: IAppState) => imageReducer),
        backgroundImage = { backgroundImage: `url(${imageSelector.images?.header})` };


    return (
        <header className="py-5 header-background" style={backgroundImage}>
            <img className="img-fluid d-block mx-auto" src={imageSelector.images?.headerText} alt="Clifton Cobbler" style={{ maxWidth: '100%' }} />
        </header>
    );
}