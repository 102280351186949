export default{
    Common: {
        address: "24 Princess Victoria Street, Clifton, Bristol, BS8 4BU, 0117 974 4226"
    },
    Intro:{
        heading: "Professional Shoe Repairer"
    },
    Contact:{
        heading: "Contact Us..."
    }, 

}