import React from 'react';
import './style.scss';

const  LoadingSpinner = () => (    
    <div className="text-center">
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <div>Please wait. Loading data.</div>
    </div>
);

export default LoadingSpinner;