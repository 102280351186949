import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { IContent } from '../../interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { getContentsForPage } from '../../Api/Content';
import { IAppState } from '../../state';
import './style.scss';
import LoadingSpinner from '../LoadingSpinner';



interface IProps {
    pageId: string;
    image?: any;
}

export default function PageContent(props: IProps) {

    const
        dispatch = useDispatch(),
        [data, setData] = React.useState<IContent[]>([{ id: "", pageId: "", content: "" }]),
        contentData: { contents: IContent[], error?: string, loading: boolean } = useSelector(({ contentReducer }: IAppState) => contentReducer);

    function Image(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>) {
        return <img {...props} style={{margin: '10px' }} alt=""/>
    };

    React.useEffect(() => {
        console.log(`pageId: ${props.pageId}`);
        dispatch(getContentsForPage(props.pageId));
    }, [dispatch, props])

    React.useEffect(() => {
        contentData.contents.length > 0 ? setData(contentData.contents) : setData([{ id: "", pageId: props.pageId, content: "" }]);
    }, [contentData.contents, props.pageId])

    const getContent = () => {
        let content: any = [];
        data.forEach(d => {
            content.push(
                <div className="container" key={d.id}>
                    <ReactMarkdown source={d.content} renderers={{image: Image}} />
                </div>
            )
        });
        return content;
    };

    var output = (<LoadingSpinner />)

    return (
        <React.Fragment>            
            {
                contentData.loading
                ? output
                : getContent()
            }

        </React.Fragment>
    );
};