import * as React from 'react';
import Footer from '../../../components/Footer';
import FullWidth from '../../../components/Header/FullWidth';
import NavigationBar from '../../../components/NavigationBar';
import CallOut from '../../../components/CallOut';

type MainLayoutProps = {
    page: string;    
};

    const MainLayout: React.FunctionComponent<MainLayoutProps>= ({page, children}) =>{

        return(
        <div>
            <NavigationBar active={page} /> 
            <FullWidth />   
            {children}            
            <CallOut />
            <Footer />        
        </div>
        )
};

export default MainLayout


