
import { FETCH_IMAGES, FETCH_IMAGES_PENDING } from '../../state/action-types';
import {IIMages} from '../../interfaces';
import axios from 'axios';
import {API_URL} from '../../common'
import { IReducerAction } from '../../state/IReducerAction';

export function getActiveImages() {     
    return (dispatch: (action: IReducerAction) => any) => { 
        dispatch({type: FETCH_IMAGES_PENDING});
        axios.get(`${API_URL}/Image/true`)
        .then (res =>
            dispatch({
                type: FETCH_IMAGES,
                payload: res.data as IIMages
            })       
        )             
    
    };
};