import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import ContactForm from '../../components/ContactForm';


export default function Contact(){

    return(
        <div>
            <MainLayout page="/contact">             
            <ContactForm />
            </MainLayout>        
        </div>
    )
};