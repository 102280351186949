import { FETCH_IMAGES, FETCH_IMAGES_ERROR, FETCH_IMAGES_PENDING } from '../action-types';
import { IReducerAction } from '../IReducerAction';
import {IIMages} from '../../interfaces';

export class ImageState{
    loading: boolean;
    images?: any;
    imagesList: IIMages[];
    error?: any;

    constructor(){
        this.loading = true;
        this.images = null
        this.imagesList = [{id:"", logo:"", header:"", footer:"", headerText:"", active: false}];
        this.error = null;
    }
}

export default function imagesReducer(state = new ImageState(), action: IReducerAction) {
    const {type, payload} = action || {} as IReducerAction;
    switch (type) {
        case FETCH_IMAGES_PENDING: return {...state, loading: true}
        case FETCH_IMAGES: return { ...state, images: payload as IIMages,loading: false }        
        default: return state;
    }
}