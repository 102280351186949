import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../scss/style.scss';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.scss';
import { IIMages } from '../../interfaces';
import { IAppState } from '../../state';
import { useSelector } from 'react-redux';

// export enum Pages{
//     home,
//     about,
//     services,
//     contact,
//     landingAdmin
// }

interface IProps {
  active: string;
}

export default function NavigationBar(props: IProps) {

  // const logo = require('../../images/logo-full.png')

  const
    imageSelector: { images?: IIMages, error?: string } = useSelector(({ imageReducer }: IAppState) => imageReducer);


  return (
    <Navbar expand="lg" className="bg-white" fixed="top">
      <div className="container">
        <Navbar.Brand className="cb-brand ">
          <Link to="/" className="navbar-brand">
            <img src={imageSelector.images?.logo} className="logo-img" alt="Clifton Cobbler Logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav className="ml-auto">
            <Nav.Item className={`nav-item ${props.active === "/home" && "active"}`} >
              <Link className="nav-link" to="/" >Home</Link>
            </Nav.Item>
            <Nav.Item className={`nav-item ${props.active === "/about" && "active"}`} >
              <Link className="nav-link" to={{
                pathname: '/about',
                state: { pageId: 'about' }
              }} >About</Link>
            </Nav.Item>
            <Nav.Item className={`nav-item ${props.active === "/services" && "active"}`} >
              <Link className="nav-link" to={{
                pathname: '/services',
                state: { pageId: 'services' }
              }} >Services</Link>
            </Nav.Item>
            <Nav.Item className={`nav-item ${props.active === "/contact" && "active"}`} >
              <Link className="nav-link" to="/contact" >Contact</Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>

  );
};
