import { Reducer } from 'redux';
import { createBrowserHistory } from 'history'
import {applyMiddleware, createStore } from "redux";
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistCombineReducers, PersistConfig} from 'redux-persist';
import introReducer from './reducers/introReducer';
import contentReducer from './reducers/contentReducer';
import emailReducer from './reducers/emailReducer';
import imageReducer from './reducers/imageReducer';
import { IAppState } from './index';
import { createBlacklistFilter, createWhitelistFilter } from 'redux-persist-transform-filter';
import sessionStorage from 'redux-persist/lib/storage/session';
import { connectRouter, routerMiddleware } from 'connected-react-router';

const
    persistConfig: PersistConfig<IAppState> = {
        key: "root",
        storage: sessionStorage,
        transforms:[
            createWhitelistFilter("router",[]),            
            createWhitelistFilter("introReducer", ["intros"]),
            createWhitelistFilter("contentReducer", ["contents"]), 
            createWhitelistFilter("imageReducer", ["images"]),       
        ]
    },
    history = createBrowserHistory({basename: ""}),
    appReducer = persistCombineReducers(persistConfig,{        
        router: connectRouter(history) as Reducer,
        introReducer,
        contentReducer,
        emailReducer,
        imageReducer
    }),
    { __serverState } = (window || {}) as any,
    store = createStore(
        appReducer,
        __serverState || {},
        composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
    ),
    persistor = persistStore(store, undefined,() =>{});

    export default store;
    export {persistor, history, appReducer};