import * as React from 'react';
import './style.scss';
import { useSelector } from 'react-redux';
import { IIMages } from '../../interfaces';
import { IAppState } from '../../state';


export default function CallOut() {
    const
        imageSelector: { images?: IIMages, error?: string } = useSelector(({ imageReducer }: IAppState) => imageReducer),
        backgroundImage = { backgroundImage: `url(${imageSelector.images?.footer})` };

    return (
        <section className="py-5 bg-full" style={backgroundImage}>
        </section>
    )
};