import * as React from 'react';
import Heading from '../Heading';
import { useDispatch, useSelector } from 'react-redux';
import fetchIntro from '../../Api/index'
import ReactMarkdown from 'react-markdown';
import { IIntro } from '../../interfaces';
import { IAppState } from '../../state';
import LoadingSpinner from '../LoadingSpinner';



export default function Intro() {


  const
    dispatch = useDispatch(),
    contents: { intros: IIntro[], error?: string, loading: boolean } = useSelector(({ introReducer }: IAppState) => introReducer);

    function Image(props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLImageElement> & React.ImgHTMLAttributes<HTMLImageElement>) {
      return <img {...props} style={{margin: '10px' }} alt=""/>
  };
    
  React.useEffect(() => {
    dispatch(fetchIntro());
  }, [dispatch]);



  const buildIntro = () => {
    let intro: any = [];

    contents.intros.forEach(d => {
      intro.push(
        <React.Fragment key={d.id}>
          <Heading heading={d.heading} lead={d.lead} />
          <ReactMarkdown source={d.content} renderers={{image: Image}} />
        </React.Fragment>)
    });
    return intro;
  };

  var output =(<LoadingSpinner/>);

  return (    
      <div className="container">
        {
          contents.loading
          ? output
          : buildIntro()
        }
      </div>    
  );

};