import React from 'react';
import Router from './Router';
import { useDispatch } from 'react-redux';
import {getActiveImages} from './Api/Images';

function App() {

  const dispatch = useDispatch();

  React.useEffect(() => {        
    dispatch(getActiveImages());
  },[dispatch])

  return (
    <Router />
  );
}

export default App;
