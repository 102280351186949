import { POST_EMAIL_ERROR, POST_EMAIL_SUCCESS, EMAIL_RESET,POST_EMAIL_PENDING } from '../action-types';
import { IReducerAction } from '../IReducerAction';


export class EmailState{
    loading: boolean;
    data?: any;    
    error?: any;
    success: boolean;

    constructor(){
        this.loading = false;
        this.data= null;        
        this.error = null;
        this.success = false;
    }
}

export default function emailReducer(state = new EmailState(), action: IReducerAction){
    const {type, payload} = action || {} as IReducerAction;        
    switch(type) {
        case POST_EMAIL_PENDING:
            console.log("POST_EMAIL_PENDING");
            return {...state, loading: true}
        case POST_EMAIL_SUCCESS: return { ...state, data: "Message sent successfully, we'll be in touch shortly.", success: true, error: null, loading: false }
        case POST_EMAIL_ERROR: return { ...state, error: "failed to send, please try again.", success: false, data: null, loading: false }
        case EMAIL_RESET: return {...state, data: state.data, success: false, error: null, loading: false}
        default:
            return state;
    }
}

