import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import { useLocation } from 'react-router-dom';
import PageContent from '../../components/PageContent';

interface IPage {
    pageId: string;
}

export default function Content() {
    const
        location = useLocation(),
        page = (location.state as IPage).pageId ? location.state as IPage :
            {
                pageId: location.pathname === "/" ? "intro" : location.pathname.replace("/", "")
            };    
        

    
    return (
        <div>
            <MainLayout page={location.pathname}>
                <PageContent pageId={page.pageId} />
            </MainLayout>
        </div>
    )
};