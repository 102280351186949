import { POST_EMAIL_SUCCESS, POST_EMAIL_ERROR, EMAIL_RESET, POST_EMAIL_PENDING } from '../../state/action-types';
import { IEmail } from '../../interfaces';
import axios from 'axios';
import { API_URL } from '../../common'
import { IReducerAction } from '../../state/IReducerAction';


export async function postEmail(formData: IEmail) {
    return (dispatch: (action: IReducerAction) => any) => {        
        dispatch({type: POST_EMAIL_PENDING});
        axios.post(`${API_URL}/Email`, formData)
            .then((response) => {
                dispatch({ type: POST_EMAIL_SUCCESS, payload: "Message sent successfully, we'll be in touch shortly." })
            })
            .catch((error) => {
                if (error.response) {
                    dispatch({ type: POST_EMAIL_ERROR, payload: "Message failed to send, please try again." });
                }
                else if (error.request)
                    console.log(error.request);
                else {
                    console.log(`Error: ${error.Message}`);
                }
            })
    }
};

export function resetEmail(){
    return (dispatch:(action: IReducerAction) => any ) => {
        dispatch({type:EMAIL_RESET, payload: null})
    };
}