import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './features/Home';
import Contact from './features/Contact';
import ScrollToTop from './components/ScrollToTop';
import Content from './features/Content';



const Router = () => {
    return (
        <BrowserRouter>
        <React.Fragment>
            <ScrollToTop />
            <Switch>
                <Route exact={true} path="/" component={Home}/>                
                <Route path="/about" component={Content} />
                <Route path="/contact" component={Contact} />
                <Route path="/services" component={Content} />
                {/* Not Found */}
                <Route component={() => <Redirect to="/"/>} />
            </Switch>
            </React.Fragment>
        </BrowserRouter>    
    );
};

export default Router;

