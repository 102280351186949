export const FETCH_INTRO_DATA = 'FETCH_INTRO_DATA'; 
export const FETCH_INTRO_PENDING = 'FETCH_INTRO_PENDING'; 

export const FETCH_CONTENT = 'FETCH_CONTENT'; 
export const FETCH_CONTENT_PENDING = 'FETCH_CONTENT_PENDING';

export const POST_EMAIL_SUCCESS ='POST_EMAIL_SUCCESS';
export const POST_EMAIL_PENDING ='POST_EMAIL_PENDING';
export const POST_EMAIL_ERROR ='POST_EMAIL_ERROR';
export const EMAIL_RESET = 'EMAIL_RESET'; 

export const FETCH_IMAGES = 'FETCH_IMAGES';
export const FETCH_IMAGES_PENDING = 'FETCH_IMAGES_PENDING';
export const FETCH_IMAGES_ERROR = "FETCH_IMAGES_ERROR";