import { FETCH_CONTENT, FETCH_CONTENT_PENDING} from '../action-types';
import { IContent } from '../../interfaces';
import { IReducerAction } from '../IReducerAction';


export class ContentState{
    loading: boolean;
    content?: any;
    contents: IContent[];    
    error?: any;

    constructor(){
        this.loading = true;
        this.content= null;
        this.contents = [{id:"", pageId:"", content:""}];        
        this.error = null;
    }
}

export default function contentReducer(state = new ContentState(), action: IReducerAction) {
    const {type, payload} = action || {} as IReducerAction;
    switch (type) {
        case FETCH_CONTENT: return {...state, contents: payload as IContent[],loading: false }
        case FETCH_CONTENT_PENDING: return {...state, loading: true}
        default:
            return state;
    }
}

