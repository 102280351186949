import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import Intro from '../../components/Intro';


export default function Home(){

    return(
        <div>
            <MainLayout page="/">             
            <Intro />
            </MainLayout>
        </div>
    );
};
