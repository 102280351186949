import { FETCH_INTRO_DATA, FETCH_INTRO_PENDING } from '../action-types';
import { IReducerAction } from '../IReducerAction';
import {IIntro} from '../../interfaces';
import NavItem from 'react-bootstrap/esm/NavItem';

export class IntroState{
    loading: boolean;
    intro?: any;
    intros: IIntro[];
    error?: any;

    constructor(){
        this.loading = true;
        this.intro = null
        this.intros = [{id:"", heading:"", lead:"", content:""}];
        this.error = null;
    }
}


export default function introReducer(state = new IntroState, action: IReducerAction) {
    const {type, payload} = action || {} as IReducerAction;
    switch (type) {
        case FETCH_INTRO_PENDING: return {...state, loading: true}
        case FETCH_INTRO_DATA: return { ...state, intros: payload as IIntro[], loading: false }        
        default: return state;
    }
}