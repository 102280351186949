import { FETCH_CONTENT, FETCH_CONTENT_PENDING } from '../../state/action-types';
import {IContent} from '../../interfaces';
import axios from 'axios';
import {API_URL} from '../../common'
import { IReducerAction } from '../../state/IReducerAction';

export function getContentsForPage(pageId: string) {   
    return (dispatch: (action: IReducerAction) => any) => { 
        dispatch({type: FETCH_CONTENT_PENDING});
        axios.get(`${API_URL}/contents/${pageId}`)
        .then (res =>
            dispatch({
                type: FETCH_CONTENT,
                payload: res.data as IContent[]
            })       
        )             
    
    };
};