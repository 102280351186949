import * as React from 'react';
import ReactMarkdown from 'react-markdown';



interface IProps{
    heading: string;
    lead: string
}

export default function Heading(props: IProps){

    const
         [heading, setHeading] = React.useState(""),
         [lead, setLead] = React.useState("");

    React.useEffect(() => {
        setHeading(props.heading);
        setLead(props.lead);
    },[props]);
        
    return(
        <React.Fragment>
            {heading && <ReactMarkdown source={heading} />}
            {lead && <span className="lead"><ReactMarkdown source={lead} /></span>}
        </React.Fragment>
    );

};