import * as React from 'react';
import ResizableTextarea from '../ResizableTextarea';
import Heading from '../Heading';
import Content from '../../common/Content';
import './style.scss';
import * as EmailValidator from 'email-validator';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../state';
import { postEmail, resetEmail } from '../../Api/Email';
import LoadingSpinner from '../LoadingSpinner';




export interface IEmail{
    body: string,
    email: string,
    from: string,
    name: string,
    subject: string
};

interface IProps{    
    onChange?: (tagline: string) => void;
}

export default function ContactForm(props: IProps){
    const
        textInputEmail = React.useRef<HTMLInputElement>(null),      
        textAreaMessage = React.useRef<HTMLTextAreaElement>(null),  
        textInputName = React.useRef<HTMLInputElement>(null),
        [name, setName] = React.useState(""),
        [email, setEmail] = React.useState(""),
        [body, setBody] = React.useState(""),
        [nameValidation, setNameValidation] = React.useState(""),
        [emailValidation, setEmailValidation] = React.useState(""),
        [messageValidation, setMessageValidation] = React.useState(""),
        dispatch = useDispatch(),
        contents: { data?: string, error?: string, success: boolean, loading: boolean } = useSelector(({ emailReducer }: IAppState) => emailReducer),   

        [heading, setHeading] = React.useState(`#### ${Content.Contact.heading}`);  
    
    React.useEffect(() => {
        setHeading(`#### ${Content.Contact.heading}`)
    }, [])    

    React.useEffect(()=>{
        if(contents.success){                        
            setHeading(`#### ${contents.data}`)         
            resetForm();
        }else if(contents.error){
            setHeading(`#### ${contents.error}`);         
        }
    }, [contents])

    const handleSubmission = async () => {        
        let formData = {
            body: `REPLY TO: ${email}\r\n\r\n${body}`, 
            email, 
            from:"website@cliftoncobbler.com",
            to: "enquiries@cliftoncobbler.com",
            name,
            subject: "DO NOT REPLY - Enquiry from cliftoncobbler.co.uk"
        };
        validate() && dispatch(await postEmail(formData));          
        
    };

    const validate = () =>{
        setNameValidation("");
        setEmailValidation("");
        setMessageValidation("");

        if(!name) {
            textInputName.current && textInputName.current.focus();
            setNameValidation("Please enter your name.")
            return false;
        } 
        if(!email){
            textInputEmail.current && textInputEmail.current.focus();
            setEmailValidation("Please enter your email address");
            return false;
        }
        else if(!EmailValidator.validate(email)){
            textInputEmail.current && textInputEmail.current.focus();
            setEmailValidation("Please enter a valid email address");
            return false;
        }
        if(!body){
            textAreaMessage.current && textAreaMessage.current.focus();
            setMessageValidation("Please enter a message.");
            return false;
        }

        return true;
        
    };

    
    const resetForm = () => {
        setName("");
        setBody("");
        setEmail(""); 
        dispatch(resetEmail());       
    };

    const handleMessageChange = (text: string) => {                     
        setBody(text);
    };

    var spinner = (<LoadingSpinner />);

    return(

        <section className="py-2">
            <div className="container">                
                <Heading heading={heading} lead={Content.Common.address} />
                {contents.loading ? spinner : ""}
                <form id="contact-form">
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                        <input type="text" ref={textInputName} className="form-control" value={name || ""} onChange={e => setName(e.target.value)} required
                        placeholder="Your Name" id="nameInput" tabIndex={1}/>
                        </div>
                        <div className="text-danger">{nameValidation}</div>
                    </div>
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                        <input type="text" ref={textInputEmail} className="form-control" value={email || ""} onChange={e => setEmail(e.target.value)} required
                        placeholder="Your email address" id="emailInput" tabIndex={2}/>
                        </div>
                        <div className="text-danger">{emailValidation}</div>
                    </div>
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                        <ResizableTextarea placeHolder="Message" tabIndex={3} ref={textAreaMessage} value={body} onUpdate={ e => handleMessageChange(e.currentTarget.value) }/>
                        </div>
                        <div className="text-danger">{messageValidation}</div>
                    </div>
                    <div className="form-group">
                        <div className="input-group input-group-lg">
                        <button className="btn btn-block btn-lg btn-primary" onClick={e => {e.preventDefault(); handleSubmission()}}>Send</button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
};