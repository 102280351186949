import {FETCH_INTRO_DATA, FETCH_INTRO_PENDING} from '../state/action-types';
import { IIntro } from '../interfaces';
import { IReducerAction } from '../state/IReducerAction';
import axios from 'axios';
import {API_URL} from '../common/'



export default function fetchIntro() {    

    return (dispatch: (action: IReducerAction) => any) => { 
        dispatch({type: FETCH_INTRO_PENDING})                
        axios.get(`${API_URL}/Intro`)
        .then (res =>
            dispatch({
                type: FETCH_INTRO_DATA,
                payload: res.data as IIntro[] 
            })       
        )             
    
    };
};

